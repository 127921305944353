export const albums_data = {
   "animals": {
      "name": "Animals",
      "photos": [
         {
            "faces": [],
            "height": 3456,
            "name": "230530-1.jpg",
            "slug": "230530-1-jpg",
            "src": "/static/_gallery/albums/animals/1600x1066_230530-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/animals/1024x682_230530-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/animals/1600x1066_230530-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/animals/500x333_230530-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/animals/800x533_230530-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/animals/500x333_230530-1-jpg.jpg",
            "width": 5184
         },
         {
            "faces": [],
            "height": 3456,
            "name": "230530-2.jpg",
            "slug": "230530-2-jpg",
            "src": "/static/_gallery/albums/animals/1600x1066_230530-2-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/animals/1024x682_230530-2-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/animals/1600x1066_230530-2-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/animals/500x333_230530-2-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/animals/800x533_230530-2-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/animals/500x333_230530-2-jpg.jpg",
            "width": 5184
         },
         {
            "faces": [],
            "height": 3456,
            "name": "230530-3.jpg",
            "slug": "230530-3-jpg",
            "src": "/static/_gallery/albums/animals/1600x1066_230530-3-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/animals/1024x682_230530-3-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/animals/1600x1066_230530-3-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/animals/500x333_230530-3-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/animals/800x533_230530-3-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/animals/500x333_230530-3-jpg.jpg",
            "width": 5184
         },
         {
            "faces": [],
            "height": 3456,
            "name": "230530-4.jpg",
            "slug": "230530-4-jpg",
            "src": "/static/_gallery/albums/animals/1600x1066_230530-4-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/animals/1024x682_230530-4-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/animals/1600x1066_230530-4-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/animals/500x333_230530-4-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/animals/800x533_230530-4-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/animals/500x333_230530-4-jpg.jpg",
            "width": 5184
         },
         {
            "faces": [],
            "height": 720,
            "name": "IMG_4636.jpeg",
            "slug": "img-4636-jpeg",
            "src": "/static/_gallery/albums/animals/1280x720_img-4636-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/animals/1024x576_img-4636-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/animals/1280x720_img-4636-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/animals/500x281_img-4636-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/animals/800x450_img-4636-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/animals/500x281_img-4636-jpeg.jpeg",
            "width": 1280
         },
         {
            "faces": [],
            "height": 960,
            "name": "IMG_4698.jpeg",
            "slug": "img-4698-jpeg",
            "src": "/static/_gallery/albums/animals/1280x960_img-4698-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/animals/1024x768_img-4698-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/animals/1280x960_img-4698-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/animals/500x375_img-4698-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/animals/800x600_img-4698-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/animals/500x375_img-4698-jpeg.jpeg",
            "width": 1280
         }
      ],
      "slug": "animals",
      "src": "/static/_gallery/albums/animals/500x333_230530-1-jpg.jpg"
   },
   "landscapes": {
      "name": "Landscapes",
      "photos": [
         {
            "faces": [],
            "height": 960,
            "name": "IMG_4207.jpeg",
            "slug": "img-4207-jpeg",
            "src": "/static/_gallery/albums/landscapes/1280x960_img-4207-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/landscapes/1024x768_img-4207-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/landscapes/1280x960_img-4207-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/landscapes/500x375_img-4207-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/landscapes/800x600_img-4207-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/landscapes/500x375_img-4207-jpeg.jpeg",
            "width": 1280
         },
         {
            "faces": [],
            "height": 853,
            "name": "IMG_4459.jpeg",
            "slug": "img-4459-jpeg",
            "src": "/static/_gallery/albums/landscapes/1280x853_img-4459-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/landscapes/1024x682_img-4459-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/landscapes/1280x853_img-4459-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/landscapes/500x333_img-4459-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/landscapes/800x533_img-4459-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/landscapes/500x333_img-4459-jpeg.jpeg",
            "width": 1280
         },
         {
            "faces": [],
            "height": 853,
            "name": "IMG_4493.jpeg",
            "slug": "img-4493-jpeg",
            "src": "/static/_gallery/albums/landscapes/1280x853_img-4493-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/landscapes/1024x682_img-4493-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/landscapes/1280x853_img-4493-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/landscapes/500x333_img-4493-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/landscapes/800x533_img-4493-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/landscapes/500x333_img-4493-jpeg.jpeg",
            "width": 1280
         },
         {
            "faces": [],
            "height": 853,
            "name": "IMG_4652.jpeg",
            "slug": "img-4652-jpeg",
            "src": "/static/_gallery/albums/landscapes/1280x853_img-4652-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/landscapes/1024x682_img-4652-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/landscapes/1280x853_img-4652-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/landscapes/500x333_img-4652-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/landscapes/800x533_img-4652-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/landscapes/500x333_img-4652-jpeg.jpeg",
            "width": 1280
         },
         {
            "faces": [],
            "height": 853,
            "name": "IMG_4653.jpeg",
            "slug": "img-4653-jpeg",
            "src": "/static/_gallery/albums/landscapes/1280x853_img-4653-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/landscapes/1024x682_img-4653-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/landscapes/1280x853_img-4653-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/landscapes/500x333_img-4653-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/landscapes/800x533_img-4653-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/landscapes/500x333_img-4653-jpeg.jpeg",
            "width": 1280
         }
      ],
      "slug": "landscapes",
      "src": "/static/_gallery/albums/landscapes/500x375_img-4207-jpeg.jpeg"
   },
   "people": {
      "name": "People",
      "photos": [
         {
            "faces": [],
            "height": 3456,
            "name": "230530-5.jpg",
            "slug": "230530-5-jpg",
            "src": "/static/_gallery/albums/people/1600x1066_230530-5-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/people/1024x682_230530-5-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/people/1600x1066_230530-5-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/people/500x333_230530-5-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/people/800x533_230530-5-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/people/500x333_230530-5-jpg.jpg",
            "width": 5184
         },
         {
            "faces": [],
            "height": 3456,
            "name": "230530-8.jpg",
            "slug": "230530-8-jpg",
            "src": "/static/_gallery/albums/people/1600x1066_230530-8-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/people/1024x682_230530-8-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/people/1600x1066_230530-8-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/people/500x333_230530-8-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/people/800x533_230530-8-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/people/500x333_230530-8-jpg.jpg",
            "width": 5184
         },
         {
            "faces": [],
            "height": 3456,
            "name": "230530-9.jpg",
            "slug": "230530-9-jpg",
            "src": "/static/_gallery/albums/people/1600x1066_230530-9-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/people/1024x682_230530-9-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/people/1600x1066_230530-9-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/people/500x333_230530-9-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/people/800x533_230530-9-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/people/500x333_230530-9-jpg.jpg",
            "width": 5184
         },
         {
            "faces": [],
            "height": 3456,
            "name": "230531-3.jpg",
            "slug": "230531-3-jpg",
            "src": "/static/_gallery/albums/people/1600x1066_230531-3-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/people/1024x682_230531-3-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/people/1600x1066_230531-3-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/people/500x333_230531-3-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/people/800x533_230531-3-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/people/500x333_230531-3-jpg.jpg",
            "width": 5184
         },
         {
            "faces": [],
            "height": 3456,
            "name": "230531-5.jpg",
            "slug": "230531-5-jpg",
            "src": "/static/_gallery/albums/people/1600x1066_230531-5-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/people/1024x682_230531-5-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/people/1600x1066_230531-5-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/people/500x333_230531-5-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/people/800x533_230531-5-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/people/500x333_230531-5-jpg.jpg",
            "width": 5184
         },
         {
            "faces": [],
            "height": 853,
            "name": "IMG_3812.jpeg",
            "slug": "img-3812-jpeg",
            "src": "/static/_gallery/albums/people/1280x853_img-3812-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/people/1024x682_img-3812-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/people/1280x853_img-3812-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/people/500x333_img-3812-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/people/800x533_img-3812-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/people/500x333_img-3812-jpeg.jpeg",
            "width": 1280
         },
         {
            "faces": [],
            "height": 853,
            "name": "IMG_3845.jpeg",
            "slug": "img-3845-jpeg",
            "src": "/static/_gallery/albums/people/1280x853_img-3845-jpeg.jpeg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/people/1024x682_img-3845-jpeg.jpeg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/people/1280x853_img-3845-jpeg.jpeg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/people/500x333_img-3845-jpeg.jpeg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/people/800x533_img-3845-jpeg.jpeg"
               ]
            },
            "thumb": "/static/_gallery/albums/people/500x333_img-3845-jpeg.jpeg",
            "width": 1280
         }
      ],
      "slug": "people",
      "src": "/static/_gallery/albums/people/500x333_230530-5-jpg.jpg"
   },
   "plants": {
      "name": "Plants",
      "photos": [
         {
            "faces": [],
            "height": 3456,
            "name": "230530-10.jpg",
            "slug": "230530-10-jpg",
            "src": "/static/_gallery/albums/plants/1600x1066_230530-10-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/plants/1024x682_230530-10-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/plants/1600x1066_230530-10-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/plants/500x333_230530-10-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/plants/800x533_230530-10-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/plants/500x333_230530-10-jpg.jpg",
            "width": 5184
         },
         {
            "faces": [],
            "height": 3456,
            "name": "230530-11.jpg",
            "slug": "230530-11-jpg",
            "src": "/static/_gallery/albums/plants/1600x1066_230530-11-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/plants/1024x682_230530-11-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/plants/1600x1066_230530-11-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/plants/500x333_230530-11-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/plants/800x533_230530-11-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/plants/500x333_230530-11-jpg.jpg",
            "width": 5184
         },
         {
            "faces": [],
            "height": 3456,
            "name": "230530-6.jpg",
            "slug": "230530-6-jpg",
            "src": "/static/_gallery/albums/plants/1600x1066_230530-6-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/plants/1024x682_230530-6-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/plants/1600x1066_230530-6-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/plants/500x333_230530-6-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/plants/800x533_230530-6-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/plants/500x333_230530-6-jpg.jpg",
            "width": 5184
         },
         {
            "faces": [],
            "height": 3456,
            "name": "230530-7.jpg",
            "slug": "230530-7-jpg",
            "src": "/static/_gallery/albums/plants/1600x1066_230530-7-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/plants/1024x682_230530-7-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/plants/1600x1066_230530-7-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/plants/500x333_230530-7-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/plants/800x533_230530-7-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/plants/500x333_230530-7-jpg.jpg",
            "width": 5184
         },
         {
            "faces": [],
            "height": 3456,
            "name": "230531-1.jpg",
            "slug": "230531-1-jpg",
            "src": "/static/_gallery/albums/plants/1600x1066_230531-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/plants/1024x682_230531-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/plants/1600x1066_230531-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/plants/500x333_230531-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/plants/800x533_230531-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/plants/500x333_230531-1-jpg.jpg",
            "width": 5184
         },
         {
            "faces": [],
            "height": 3456,
            "name": "230531-2.jpg",
            "slug": "230531-2-jpg",
            "src": "/static/_gallery/albums/plants/1600x1066_230531-2-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/plants/1024x682_230531-2-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/plants/1600x1066_230531-2-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/plants/500x333_230531-2-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/plants/800x533_230531-2-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/plants/500x333_230531-2-jpg.jpg",
            "width": 5184
         },
         {
            "faces": [],
            "height": 3456,
            "name": "230531-4.jpg",
            "slug": "230531-4-jpg",
            "src": "/static/_gallery/albums/plants/1600x1066_230531-4-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/plants/1024x682_230531-4-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/plants/1600x1066_230531-4-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/plants/500x333_230531-4-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/plants/800x533_230531-4-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/plants/500x333_230531-4-jpg.jpg",
            "width": 5184
         }
      ],
      "slug": "plants",
      "src": "/static/_gallery/albums/plants/500x333_230530-10-jpg.jpg"
   }
};